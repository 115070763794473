import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { firstValueFrom } from 'rxjs';
import { ConfigModule } from 'src/app/util/config';
import { CustomDateAdapterService } from 'src/app/util/customDateAdapter';
import { MatPaginatorIntlCro } from 'src/app/util/matPaginatorIntlCroClass';

@Component({
  selector: 'app-time-tracking-month-list',
  templateUrl: './time-tracking-month-list.component.html',
  styleUrls: ['./time-tracking-month-list.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapterService,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: { display: { dateInput: 'monthYearLabel' } } },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
  ]
})
export class TimeTrackingMonthListComponent {
  constructor(private _http: HttpClient,
    private _dialog: MatDialog,
    private _config: ConfigModule
  ) { }

  ngOnInit(): void {
    this.loadTimes();
    this.currentMonth.valueChanges.subscribe(() => {
      this.loadTimes();
    });
  }

  max = new Date();

  @ViewChild('picker')
  picker!: MatDatepicker<Date>;

  @Input()
  currentDate: FormControl;

  @Output()
  hasJumped: EventEmitter<void> = new EventEmitter();

  currentMonth = new FormControl(new Date());
  dataSource = new MatTableDataSource<any>();
  showLoader = true;

  displayedColumns = ['date', 'start', 'end', 'total', 'action']
  dayjs = dayjs;

  onMonthSelected(event: MatDatepickerInputEvent<Date>) {
    this.picker.close();
    console.log(event);
  }


  previousDate() {
    const dt = dayjs(this.currentMonth.value).subtract(1, 'month');
    this.currentMonth.setValue(dt.toDate());
  }

  nextDate() {
    const dt = dayjs(this.currentMonth.value).add(1, 'month');
    if (dt.isAfter(this.max, 'd')) return;
    this.currentMonth.setValue(dt.toDate());
  }

  jumpToDay(date: string) {
    this.currentDate.setValue(dayjs(date).toDate());
    this.hasJumped.emit();
  }

  async loadTimes() {
    this.showLoader = true;
    try {
      const result = await firstValueFrom(this._http.get<any>(`api/time?view=month&date=${dayjs
        (this.currentMonth.value).startOf('month').format('YYYY-MM-DD')}`));
      const data = result.data;
      data.push({
        total: result.total
      });
      console.log(data);
      this.dataSource.data = data;

    } catch (error) {
      if (error?.error?.error) {
        this._config.showSnackbar(error.error.error, 3000, 'error');
        return;
      }
      this._config.showSnackbar('Konnte Buchungen nicht abrufen!', 3000, 'error');
    } finally {
      this.showLoader = false;
    }
  }
}
