import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { ReplaySubject, Subscription, filter, firstValueFrom, skip } from 'rxjs';
import { ConfigModule, getHostUrl } from 'src/app/util/config';
import { CustomDateAdapterService, DATE_FORMATS } from 'src/app/util/customDateAdapter';
import { QrScannerComponent } from 'src/app/util/dialogs/qr-scanner/qr-scanner.component';
import { Login } from 'src/app/util/login';
import Swal from 'sweetalert2';
import { jsPDF } from 'jspdf';
import { SignatureDialogComponent } from 'src/app/util/dialogs/signature-dialog/signature-dialog.component';
import { NfcScannerDialogComponent } from 'src/app/util/dialogs/nfc-scanner-dialog/nfc-scanner-dialog.component';
import { ActionAbortEvent, AppCommunicationService, BarcodeScannedEvent } from 'src/app/util/services/app-communication/app-communication.service';

@Component({
  selector: 'app-material-detail',
  templateUrl: './material-detail.component.html',
  styleUrls: ['./material-detail.component.css'],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapterService },
  { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }],
})
export class MaterialDetailComponent {
  constructor(
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router,
    private _config: ConfigModule,
    private _builder: FormBuilder,
    public _login: Login,
    private _dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    public _com: AppCommunicationService) { }

  @ViewChild('menu', { static: false }) menu: TemplateRef<any>;
  @ViewChild('qrcode', { static: false }) qrcode: TemplateRef<any>;

  dayjs = dayjs;
  JSON = JSON;
  today = new Date();

  fileList = [];
  data: any = {};

  filteredCategories: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  categoryList = [];
  categoryFilterCtrl: FormControl<string> = new FormControl<string>('');

  filteredCategoriesOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  categoryOptionFilterCtrl: FormControl<string> = new FormControl<string>('');

  filteredStorage: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  storageList = [];
  storageFilterCtrl: FormControl<string> = new FormControl<string>('');

  filteredUser: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  userList = [];
  userFilterCtrl: FormControl<string> = new FormControl<string>('');

  subscription: Subscription;

  @ViewChild('FileSelectInputDialog') FileSelectInputDialog: ElementRef;

  checkCycles = [];
  unit = '';

  materialId = null;
  consumables = false;

  baseURL = getHostUrl();

  isSaving = false;
  isLoading = true;

  group: FormGroup = new FormGroup({});

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.group = this._builder.group({
      name: [''],
      status: ['active', Validators.required],
      storageId: [null, [Validators.required]],
      userId: [null, [Validators.required]],
      categoryId: [null, [Validators.required, Validators.min(1)]],
      serialNumber: [''],
      purchasePrice: [null],
      option: [null],
      warranty: [null],
      expiration: [null],
      stock: [-1, Validators.required],
      manufacturer: [''],
      supplier: [''],
      comment: [''],
      rememberDate: [null],
      commissioning: [null]
    });

    this.materialId = this._route.snapshot.params?.materialId;
    if (this.materialId) {
      this.loadMaterial();
      this.loadCheckCycles();
      this._config.setTitle('Material bearbeiten');
      this.group.get('name').setValidators(Validators.required);
      this.group.get('name').updateValueAndValidity();
    } else {
      this._config.setTitle('Material erstellen');

      this.isLoading = false;
    }

    this.loadStorage();
    this.loadCategories();
    this.loadUser();

    this.group.get('categoryId').valueChanges.subscribe(() => this.filterCategoriesOptions());
    this.group.get('rememberDate').valueChanges.subscribe(() => this.checkNotification());

    this.categoryFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterCategories();
      });
    this.categoryOptionFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterCategoriesOptions();
      });
    this.storageFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterStorage();
      });
    this.userFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterUser();
      });
  }

  evaluateValidatorStorageUser() {
    this.group.get('userId').setValidators([Validators.required]);
    this.group.get('storageId').setValidators([Validators.required]);

    if (this.group.get('storageId').value) {
      this.group.get('userId').setValidators([]);
      this.group.get('userId').updateValueAndValidity();
    } else if (this.group.get('userId').value) {
      this.group.get('storageId').setValidators([]);
      this.group.get('storageId').updateValueAndValidity();
    }
    setTimeout(() => this.group.markAllAsTouched());
  }

  protected filterCategories() {
    if (!this.categoryList) {
      return;
    }
    let search = this.categoryFilterCtrl.value;
    if (!search) {
      this.filteredCategories.next(this.categoryList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCategories.next(
      this.categoryList.filter(category => category.name.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterCategoriesOptions() {
    const category = this.categoryList.find(category => category.id == this.group.get('categoryId').value);
    if (!category) {
      this.filteredCategoriesOptions.next([]);
      return;
    }
    const options = category?.options?.split(',') ?? [];

    let search = this.categoryOptionFilterCtrl.value;
    if (!search) {
      this.filteredCategoriesOptions.next(options.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCategoriesOptions.next(
      options.filter(option => option.toLowerCase().indexOf(search) > -1)
    );
  }

  async openNFCScanner(field: string) {
    const result = await firstValueFrom(this._dialog.open(NfcScannerDialogComponent, {
      width: '250px'
    }).afterClosed());

    if (result) {
      this.group.get(field)!.setValue(result.text?.[0] ?? result?.uid ?? '');
    }
  }

  protected filterUser() {
    if (!this.userList) {
      return;
    }
    let search = this.userFilterCtrl.value;
    if (!search) {
      this.filteredUser.next(this.userList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredUser.next(
      this.userList.filter(user => String(user.id).toLowerCase().indexOf(search) > -1 || user.firstname.toLowerCase().indexOf(search) > -1 || user.lastname.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterStorage() {
    if (!this.storageList) {
      return;
    }
    let search = this.storageFilterCtrl.value;
    if (!search) {
      this.filteredStorage.next(this.storageList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredStorage.next(
      this.storageList.filter(storage => storage.name.toLowerCase().indexOf(search) > -1 || storage.vehicle?.toLowerCase()?.indexOf(search) > -1)
    );
  }

  async saveData() {
    this.group.disable();
    this.isSaving = true;

    const value = this.group.getRawValue();

    if (value.rememberDate) value.rememberDate = dayjs(value.rememberDate).format('YYYY-MM-DD');
    if (value.warranty) value.warranty = dayjs(value.warranty).format('YYYY-MM-DD');
    if (value.commissioning) value.commissioning = dayjs(value.commissioning).format('YYYY-MM-DD');
    if (value.expiration) value.expiration = dayjs(value.expiration).format('YYYY-MM-DD');
    if (!this.consumables) {
      value.qty = -1;
    }

    try {
      if (this.materialId) {
        await firstValueFrom(this._http.post(`${getHostUrl()}admin/material/equipment/${this.materialId}`, value));
        this.loadCheckCycles(false);
      } else {
        const result = await firstValueFrom(this._http.put<any>(`${getHostUrl()}admin/material/equipment`, value));
        this._router.navigate(['material/equipment/details', result.materialId]);
      }
      this._config.showSnackbar("Material wurde gespeichert!", 30000, 'success');
      this.isSaving = false;
      this.group.enable();
    } catch (e) {
      this.isSaving = false;
      this.group.enable();
      if (e?.status == 409) {
        return this._config.showSnackbar("Material Name oder Seriennummer wird bereits verwendet!", 3000, 'error');
      }
      this._config.showSnackbar("Konnte Material nicht speichern!", 3000, 'error');
    }
  }

  async loadStorage() {
    this.storageList = (await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/storage`)));
    this.filterStorage();
  }

  async loadUser() {
    this.userList = (await firstValueFrom(this._http.get<any>(`${getHostUrl()}user/all`)));
    this.filterUser();
  }

  async loadCategories() {
    this.categoryList = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/categories`));
    this.filterCategories();
    this.filterCategoriesOptions();
  }

  async loadCheckCycles(ignorePrevious = true) {
    const previousCount = this.checkCycles.length;
    this.checkCycles = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/equipment/${this.materialId}/checks`));

    if (this.checkCycles.length > previousCount && !ignorePrevious) {
      Swal.fire({
        title: 'Neue Prüfungen verfügbar!',
        text: 'Es wurden neue Prüfungen für dieses Material hinzugefügt, bitte überprüfe diese im unteren Teil der Seite!',
        confirmButtonText: 'OK'
      });
    }
  }

  async loadMaterial() {
    try {
      const result = await firstValueFrom(this._http.get<any>(`${getHostUrl()}admin/material/equipment/${this.materialId}`));
      this.fileList = result.attachements;
      this.data = result;
      this.group.patchValue({
        name: result.name,
        status: result.status,
        storageId: result.storageId,
        userId: result.userId,
        categoryId: result.categoryId,
        serialNumber: result.serialNumber,
        ean: result.ean,
        warranty: result.warranty,
        expiration: result.expiration,
        manufacturer: result.manufacturer,
        supplier: result.supplier,
        purchasePrice: result.purchasePrice,
        stock: result.stock,
        comment: result.comment,
        rememberDate: result.rememberDate,
        commissioning: result.commissioning,
        option: result.option
      });
      this.unit = result.unit;
      this.consumables = result.consumables;
      this.isLoading = false;
      this.evaluateValidatorStorageUser()
    } catch (e) {
      if (e.status == 404) {
        this._config.showSnackbar('Material wurde nicht gefunden!', 3000, 'error');
        this._router.navigate(['material/equipment']);
      } else {
        this._config.showSnackbar('Ein Fehler ist aufgetreten, bitte versuche es erneut!', 3000, 'error');
      }
    }
  }

  openMenu() {
    const menuRef = this._bottomSheet.open(this.menu);
    menuRef.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => menuRef.dismiss());
  }

  openQRCode() {
    const menuRef = this._bottomSheet.open(this.qrcode);
    menuRef.afterOpened().subscribe(() => {
      this.printButton();
      menuRef.dismiss();
    });
    menuRef.keydownEvents()
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => menuRef.dismiss());
  }

  openScanner(field: string) {
    if (this._com.isRunningInApp()) {
      this.subscription?.unsubscribe();
      this._com.sendAction('scanner');
      this.subscription = this._com.callback.pipe(
        skip(1)
      ).subscribe(result => {
        if (result.result instanceof ActionAbortEvent) {
          this.subscription.unsubscribe();
          return;
        }
        if (result.result instanceof BarcodeScannedEvent) {
          this.subscription.unsubscribe();
          this.group.get(field).setValue(result.result.data);
        }
      });
      return;
    }

    this._dialog.open(QrScannerComponent, {
      data: {
        title: 'EAN- oder QR Code scannen'
      },
      width: '600px'
    }).afterClosed().subscribe(result => {
      if (result.value) {
        this.group.get(field).setValue(result.value);
      } else if (result.value) {
        this._config.showSnackbar('Ungültige EAN Nummer erkannt, bitte erneut scannen!', 3000, 'error');
      }
    })
  }

  attachFile() {
    const e: HTMLElement = this.FileSelectInputDialog.nativeElement;
    e.click();
  }

  uploadAttachments($event) {
    let fileList: FileList = $event.target.files;
    this.blobToBase64(fileList.item(0)).then((fileContent: any) => {
      const file = { 'name': fileList.item(0).name, 'content': fileContent, 'id': null, 'error': false };
      this.FileSelectInputDialog.nativeElement.value = null;
      this.fileList.push(file);
      this.uploadFile();
    });
  }

  blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }

  async uploadFile() {
    let file = this.fileList.find(file => file.id === null && file.error === false);
    if (!file) {
      return;
    }
    try {
      const result = await firstValueFrom(this._http.post<any>(`${getHostUrl()}admin/material/equipment/${this.materialId}/file/upload`, {
        fileName: file.name,
        fileContent: file.content
      }));
      this.fileList[this.fileList.findIndex(file_ref => file === file_ref)].id = result.fileId;
    } catch (e) {
      this.fileList[this.fileList.findIndex(file_ref => file === file_ref)].error = true;
      this._config.showSnackbar(e.error, 3000, 'error');
    }
    setTimeout(() => {
      this.uploadFile();
    });
  }

  async deleteAttachement(file) {
    const result = await Swal.fire({
      title: 'Anhang löschen?',
      text: 'Soll dieser Anhang wirklich gelöscht werden?',
      icon: 'question',
      showDenyButton: true,
      showConfirmButton: true,
      reverseButtons: true,
      confirmButtonText: 'Ja, löschen!',
      denyButtonText: 'Nein'
    });

    if (result.isConfirmed) {
      try {
        file.isDeleting = true;
        const result = await firstValueFrom(this._http.delete(`${getHostUrl()}admin/material/equipment/${this.materialId}/file/${file.id}`));
        this.fileList.splice(this.fileList.findIndex(file_ref => file_ref.id == file.id), 1);
        this._config.showSnackbar('Anhang wurde gelöscht!', 3000, 'success');
      } catch (e) {
        file.isDeleting = false;
        this._config.showSnackbar(e.error, 3000, 'error');
      }
    }
  }

  checkNotification() {
    if (this.group.get('rememberDate').value) {
      this.group.get('comment').setValidators([Validators.required]);
    } else if (this.group.get('rememberDate').value != null) {
      this.group.get('comment').setValidators([]);
      this.group.get('rememberDate').setValue(null);
    }
    this.group.get('comment').updateValueAndValidity();
  }

  printButton() {
    const canvas = document.getElementById('qrcode-data').children[0].children[0] as HTMLCanvasElement;
    const doc = new jsPDF({
      orientation: 'l',
      unit: 'px',
      format: [300, 100]
    });

    doc.html(`
    <div style="height: 100px; width: 300px">
      <img style="float: left; height: 100%" src='${canvas.toDataURL()}'/>
      <b style="float: left; margin-top: 35px; font-size: 18px; font-weight: 400; font-family: Arial, Helvetica, sans-serif">${this.data.serialNumber ?? this.data.name ?? this.materialId}</b>
    </div>`, {
      width: 300,
      x: 0,
      y: 0,
      callback: function (pdf) {
        pdf.deletePage(2);
        pdf.autoPrint();
        const win = window.open(pdf.output('bloburl'));
      }
    });
  }

  sign(): void {
    const user = this.userList.find((user) => { if (user.id === this.group.get('userId').value) { return user; } });
    let customText = `${user.firstname} ${user.lastname}`;
    this._dialog.open(SignatureDialogComponent, {
      data: {
        title: 'Ausgabe Material',
        customText: customText,
      },
    }).afterClosed().subscribe(result => {
      if (result) {
        this._http.post(`${getHostUrl()}admin/material/equipment/sign`, {
          materialId: this.materialId,
          signature: result,
          querUserID: user.id
        }).subscribe({
          next: (res) => {
            this.loadMaterial();
            this._config.showSnackbar('Ausgabeschein wurde erstellt!', 3000, 'success');
          },
          error: (err) => {
            this._config.showSnackbar('Fehler: ' + err.error, 3000, 'error');
            console.error(err);
          },
        });
      }
    });
  }

  info(): void {
    this._dialog.open(SignatureDialogComponent, {
      data: {
        title: 'Information Material'
      },
    }).afterClosed().subscribe(result => {
      if (result) {
        this._http.post(`${getHostUrl()}admin/material/equipment/info`, {
          materialId: this.materialId,
          signature: result
        }).subscribe({
          next: (res) => {
            this.loadMaterial();
            this._config.showSnackbar('Informationsschein wurde erstellt!', 3000, 'success');
          },
          error: (err) => {
            this._config.showSnackbar('Fehler: ' + err.error, 3000, 'error');
            console.error(err);
          },
        });
      }
    });
  }
}
